import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { useState } from "react";
import Home from "./Pages/Home/Home";
import About from "./Pages/About/About";
import TermsnContd from "./Pages/TermsnCondt/TermsnCondt";
import Privacypolicy from "./Pages/PrivacyPolicy/Privacypolicy";
import Refund from "./Pages/Refund/Refund";
import Faq from "./Pages/Faq/Faq";
import Contact from "./Pages/Contact/Contact";
import Blog from "./Pages/Blogs/Blog";
import Blogdetail from "./Pages/Blogs/Blogdetails";
import Course from "./Pages/Course/Course";
import Cart from "./Pages/Cart/Cart";
import SubCart from "./Pages/Cart/SubCart";
import Checkout from "./Pages/Checkout/Checkout";
import Subcheckout from "./Pages/Checkout/SubCheckout";
import Profile from "./Pages/Profile/Profile";
import Wishlist from "./Pages/Profile/Wishlist";
import Subscribe from "./Pages/Profile/Subscribe";
import Mycourse from "./Pages/Profile/Mycourse";
import Orderhistory from "./Pages/Profile/Orderhistory";
import Question from "./Pages/Profile/Question";
import Dashboard from "./Pages/Profile/Dashboard";
import Educator from "./Pages/Edcator/Educator";
import Assessmentsucess from "./Pages/Profile/Assessmentsucess";
import Coursesucess from "./Pages/Sucess/Coursesucess";
import Courselist from "./Pages/Course/Courselist";
import { TailSpin } from "react-loader-spinner";

import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./Component/Header/Header";
import Footer from "./Component/Footer/Footer";
import Accountactivation from "./Pages/Activations/Accountactivate";
import Passwordreseted from "./Pages/Activations/Passwordreseted";
import Viewcourse from "./Pages/Course/Viewcourse";
import jwt_decode from "jwt-decode";
import { loader, isLogin } from "./redux/common";
import Assessments from "./Pages/Profile/Assessments";
import Test from "./Pages/Test/Test";
import PurchasedCourse from "./Pages/Activations/CoursePurchase";
import JobListing from "./Pages/JobListing/JobListing";
import ApplyJob from "./Pages/JobListing/Applyjob";
import Training from "./Pages/Training/Training";
import TrainingCart from "./Pages/Cart/TrainingCart";
import PaymentSuccess from "./Pages/Activations/PaymentSuccess";
import PaymentFailed from "./Pages/Activations/PaymentFailed";
import Traininghistory from "./Pages/Profile/TrainingHistory";
import StartPayment from "./Pages/EOB/StartPayment";
import PaymentSuccessEob from "./Pages/EOB/PaymentSuccess";
import PaymentFailedEob from "./Pages/EOB/PaymentFailed";
import { useLocation } from "react-router-dom";
import Corporate from "./Pages/Corporate Joining/Corporate";

function App() {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const location = useLocation();
  const noHeaderFooterRoutes = [
    "/eob/startPayment",
    "/eob/payment_success",
    "/eob/payment_failed",
  ];

  // Define the onCategorySelect function
  const onCategorySelect = (selectedCategory) => {
    setSelectedCategory(selectedCategory);
  };
  const loaderStatus = useSelector((state) => state.apiReducer.loader);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  setInterval(() => {
    AuthVerifyComponent();
  }, 1000);
  const AuthVerifyComponent = () => {
    if (localStorage.getItem("oAuth")) {
      const jwt_Token_decoded = jwt_decode(localStorage.getItem("oAuth"));
      if (jwt_Token_decoded.exp * 1000 < Date.now()) {
        // console.log("comming")
        localStorage.clear();
        navigate("/");
        dispatch(isLogin(false));
        toast.error("Session expired.Please login to proceed");
      }
    }

    return <div></div>;
  };

  return (
    <>
      <TailSpin
        height="100"
        width="80"
        color="#107B38"
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperStyle={{}}
        wrapperClass="tailSpin"
        visible={loaderStatus}
      />
      {!noHeaderFooterRoutes.includes(location.pathname) && (
        <Header onCategorySelect={onCategorySelect} />
      )}

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/termsncondt" element={<TermsnContd />} />
        <Route path="/privacypolicy" element={<Privacypolicy />} />
        <Route path="/refund" element={<Refund />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blogdetail/:id" element={<Blogdetail />} />
        <Route path="/course" element={<Course />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/subcart/:id" element={<SubCart />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/subcheckout/:id" element={<Subcheckout />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/wishlist" element={<Wishlist />} />
        <Route path="/subscribe" element={<Subscribe />} />
        <Route path="/mycourse" element={<Mycourse />} />
        <Route
          path="/courses/assessments/:courseId"
          element={<Assessments />}
        />
        <Route path="/orderhistory" element={<Orderhistory />} />
        <Route path="/training_history" element={<Traininghistory />} />
        <Route path="/assessmentSucess" element={<Assessmentsucess />} />
        <Route path="/question/:sessionId" element={<Question />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/educator" element={<Educator />} />
        <Route path="/corporate" element={<Corporate />} />
        <Route path="/sucess" element={<Coursesucess />} />
        <Route path="/course-detail/:id/:title" element={<Courselist />} />
        <Route path="/account-activated/:id" element={<Accountactivation />} />
        <Route path="/payment_success" element={<PaymentSuccess />} />
        <Route path="/payment_failed" element={<PaymentFailed />} />
        <Route path="/password-reseted/:id" element={<Passwordreseted />} />
        <Route
          path="/view_my_course/:purchasedCourseId"
          element={<Viewcourse />}
        />
        <Route path="/purchased_course" element={<PurchasedCourse />} />
        <Route path="/jobs" element={<JobListing />} />
        <Route path="/applyJob" element={<ApplyJob />} />
        <Route path="/direct_training" element={<Training />} />
        <Route path="/training_cart/:id" element={<TrainingCart />} />
        <Route path="/test" element={<Test />} />
        <Route path="/eob/startPayment" element={<StartPayment />} />
        <Route path="/eob/payment_success" element={<PaymentSuccessEob />} />
        <Route path="/eob/payment_failed" element={<PaymentFailedEob />} />
      </Routes>

      {!noHeaderFooterRoutes.includes(location.pathname) && <Footer />}

      <ToastContainer />
    </>
  );
}

export default App;
