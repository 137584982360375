import React, { useEffect, useState } from "react";
import "./Educator.css";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { educatorRegisterCb } from "../../redux/user";
import { loader } from "../../redux/common";
import { useDispatch } from "react-redux";
import { categoryList } from "../../redux/courses";
import { AiFillCamera } from "react-icons/ai";
import Profimage from "../../assets/images/userimage.png";
import { Link } from "react-router-dom";
import helper from "../../helper/axiosHelper";

const Corporate = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const getRoleId = () => {
    const currentUrl = helper.baseUrl();
    if (currentUrl === "https://www.dev.backend.kareersity.com/") {
      return "676132210dc96904f722144c"; // Dev roleId
    } else if (currentUrl === "https://www.backend.kareersity.com/") {
      return "678249f3492a29cd504ea492"; // Prod roleId
    }
    return "676132210dc96904f722144c"; // Default for localhost
  };

  const signUpFn = async (userData) => {
    const signUpData = {
      email: userData.email,
      name: userData.name,
      roleId: getRoleId(),
      status: 0,
    };

    try {
      const response = await helper.postData(
        `${helper.baseUrl()}signUp`,
        signUpData
      );

      if (response.response && response.response.data) {
        toast.error(response.response.data.message);
        return;
      }

      if (response.data) {
        if (!response.data.status) {
          toast.error(response.data.message);
        } else {
          toast.success("Sign up successful!");
          reset({
            email: "",
            name: "",
            terms: false,
          });
        }
      }
    } catch (error) {
      console.error("Sign up failed:", error);
      toast.error("Something went wrong. Please try again.");
    }
  };

  return (
    <div className="EDucaTorPage">
      <div className="PageHEaders edu">
        <div className="container">
          <div className="row">
            <div className="col-12"></div>
          </div>
        </div>
      </div>
      <div className="EduCatorFrom pb-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-10">
              <div className="PAgeTit small pt-3 pb-3">
                <h2>Corporate Sign Up</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-10">
              <div className="eDUcAfo">
                <form onSubmit={handleSubmit(signUpFn)}>
                  <div className="ProFi1 ">
                    {errors.photoUrl && (
                      <small style={{ color: "red", float: "left" }}>
                        {errors.photoUrl.message}
                      </small>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="EduCf">
                        <label>Username</label>
                        <input
                          type="text"
                          className="inputFormF namecl"
                          placeholder="Username"
                          name="name"
                          id="name"
                          {...register("name", {
                            required: "Username is required",
                            pattern: {
                              value: /^[A-Za-z\s]+$/, // Accepts letters and spaces
                              message:
                                "Invalid format. User name accepts only characters and spaces",
                            },
                          })}
                        />
                        {errors.name && (
                          <small style={{ color: "red", float: "left" }}>
                            {errors.name.message}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="">
                      <div className="EduCf">
                        <label>Email Address</label>
                        <input
                          type="text"
                          className="inputFormF"
                          placeholder="Email Address"
                          name="email"
                          id="email"
                          {...register("email", {
                            required: "Email is required",
                            pattern: {
                              value:
                                /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/,
                              message: "Invalid Format",
                            },
                          })}
                        />
                        {errors.email && (
                          <small style={{ color: "red", float: "left" }}>
                            {errors.email.message}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>

                  <div>
                    <div
                      style={{
                        marginTop: "10px",
                      }}
                    >
                      <input
                        type="checkbox"
                        className="form-check-input"
                        style={{
                          width: "20px",
                          height: "20px",
                          marginRight: "10px",
                        }}
                        id="termsCheck"
                        {...register("termsAccepted", {
                          required: "You must accept the terms and conditions",
                        })}
                      />
                      <label className="form-check-label" htmlFor="termsCheck">
                        I accept the{" "}
                        <Link to="/termsncondt" target="_blank">
                          terms and conditions
                        </Link>
                      </label>
                      {errors.termsAccepted && (
                        <small
                          className="error-message d-block"
                          style={{ color: "red" }}
                        >
                          {errors.termsAccepted.message}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-12">
                      <div className="SIGBtn">
                        <div className="EduCf">
                          <button type="submit">Sign Up</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Corporate;
